import {
  SelectDetailField,
  SelectDetailFieldOption,
  TextDetailField,
} from '../../../utilities/DetailField'
import { DetailProps } from '../../../utilities/CRUDContainer'
import { Page, PageContent } from 'types'
import { PageElementEditorField } from './pageElements/PageElementEditorField'
import { datetimeDisplay } from '../../../utilities/utils'
import { CardDetailFormWrapper } from '../../../utilities/CardDetail'
import { Box } from '@mui/material'

const selectOptions: { [key: string]: SelectDetailFieldOption[] } = {
  publishedStatus: [
    { label: 'Draft', value: 'DRAFT' },
    { label: 'Published', value: 'PUBLISHED' },
  ],
}

function PageManagementFields(props: {
  editMode: boolean
  values: Page
  setFieldValue: (field: string, value: any) => void
  setFieldTouched: (field: string, touched?: boolean) => void
}) {
  return (
    <>
      <Box sx={{ maxWidth: '400px' }}>
        {props.editMode && (
          <TextDetailField
            required
            editMode={props.editMode}
            label="Title"
            name="title"
          />
        )}
      </Box>

      <TextDetailField
        required
        editMode={props.editMode}
        label="Navigation Label"
        name="navigationLabel"
      />

      <TextDetailField
        required
        editMode={props.editMode}
        label="Navigation Link"
        name="navigationValue"
      />

      <Box sx={{ maxWidth: '400px' }}>
        <SelectDetailField
          required
          editMode={props.editMode}
          label="Published Status"
          name="publishedStatus"
          options={selectOptions.publishedStatus}
        />
      </Box>

      <Box sx={{ maxWidth: '400px' }}>
        {!props.editMode && (
          <TextDetailField
            editMode={false}
            label="Update Time"
            name="updateTime"
            formatter={datetimeDisplay}
          />
        )}
      </Box>
      <PageElementEditorField editMode={props.editMode} />
    </>
  )
}

export function PageManagementDetail(props: DetailProps<Page>) {
  return (
    <CardDetailFormWrapper<Page>
      {...props}
      // need to attach a "selected" to all page elements since that isn't saved as a DB field
      item={
        props.item
          ? {
              ...props.item,
              content: props.item.content.map((e, i) => ({
                ...e,
                selected: i === 0,
              })),
            }
          : undefined
      }
      itemName="page"
      initialValues={{ content: [] as PageContent, publishedStatus: 'DRAFT' }}
      FormFieldsComponent={PageManagementFields}
      mandatoryFields={['title', 'content']}
      // Custom validate function to take care of nested data
      formValidate={async values => {
        return values.content.reduce((errors, pageElement, i) => {
          if (!pageElement.name) {
            errors[`content#${pageElement.id}#name`] = 'Name is required'
          }
          if (pageElement.type === 'TEXT' && !pageElement.text) {
            errors[`content#${pageElement.id}#text`] = 'Text is required'
          }
          if (
            pageElement.type === 'TRIPLE_IMAGE' &&
            !pageElement.photoIds.length
          ) {
            errors[`content#${pageElement.id}#photoIds`] =
              'Three photos are required'
          }
          if (pageElement.type === 'GALLERY' && !pageElement.photoIds.length) {
            errors[`content#${pageElement.id}#photoIds`] =
              'At least one photo is required'
          }

          return errors
        }, {} as { [key: string]: any })
      }}
    />
  )
}
