import { Photo } from 'types'
import { CRUDContainer } from '../../../utilities/CRUDContainer'
import { PhotoManagementDetail } from './PhotoManagementDetail'
import {
  deletePhoto,
  deletePhotos,
  fetchAllPhotos,
  fetchPhoto,
  updatePhoto,
} from '../../../utilities/api'
import { PhotoManagementTable } from './PhotoManagementTable'

export const PhotoManagement = () => {
  return (
    <CRUDContainer<Photo>
      DetailComponent={PhotoManagementDetail}
      itemDisplayName="photo"
      itemIdKey="entryId"
      listFunction={fetchAllPhotos}
      retrievalFunction={fetchPhoto}
      TableComponent={PhotoManagementTable}
      updateFunction={updatePhoto}
      deleteFunction={({ entryId }) => deletePhoto(entryId)}
      bulkDeleteFunction={items =>
        deletePhotos(items.map(item => item.entryId))
      }
    />
  )
}
