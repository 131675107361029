import { useCallback, useEffect, useRef, useState } from 'react'

export function debounce<T extends (...args: any[]) => any>(
  callback: T,
  wait: number
): () => void {
  let timeoutId: NodeJS.Timeout | null

  return (...args: Parameters<T>) => {
    clearTimeout(timeoutId!)
    timeoutId = setTimeout(() => callback(...args), wait)
  }
}

export function exposureTimeDisplay(value: string) {
  return Number(value) >= 1
    ? `${value} seconds`
    : `1/${1 / Number(value)} seconds`
}

export function fNumberDisplay(value: string) {
  return `f${value}`
}

export function datetimeDisplay(value: string) {
  return new Date(Number(value)).toLocaleString()
}

export async function fetchWithErrorCheck(
  ...args: Parameters<typeof fetch>
): Promise<ReturnType<typeof fetch>> {
  const response = await fetch(...args)
  if (!response.ok) {
    // Try to extract the "error" field from the response body and throw it
    let error
    try {
      const json = await response.json()
      const errorMessage = json.error
      error = new Error(errorMessage)
    } catch (e) {
      // If that fails, just throw the response status text
      throw new Error(response.statusText)
    }
    throw error
  }
  return response
}

export function useComponentSizeAndPosition() {
  const boxRef = useRef<HTMLDivElement | null>(null)
  const [componentSizeAndPosition, setComponentSizeAndPosition] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  })

  const resize = useCallback(() => {
    if (boxRef.current) {
      const contentRect = boxRef.current.getBoundingClientRect()
      setComponentSizeAndPosition({
        width: contentRect.width,
        height: contentRect.height,
        top: contentRect.y,
        left: contentRect.x,
      })
    }
  }, [])

  useEffect(() => {
    const observer = new ResizeObserver(resize)

    if (boxRef.current) {
      resize()
      observer.observe(boxRef.current)
    }

    // Add resize event listener for the window itself
    window.addEventListener('resize', resize)

    return () => {
      if (boxRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(boxRef.current)
      }
      window.removeEventListener('resize', resize)
    }
  }, [resize])

  return {
    boxRef,
    width: componentSizeAndPosition.width,
    height: componentSizeAndPosition.height,
    top: componentSizeAndPosition.top,
    left: componentSizeAndPosition.left,
  }
}

export function useIsMobile(breakpoint = 768) {
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' ? window.innerWidth < breakpoint : false
  )

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < breakpoint)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [breakpoint])

  return isMobile
}
