import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import { UploadManager } from './Upload'
import { PhotoManagement } from './management/PhotoManagement'
import {
  Box,
  createTheme,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { PageManagement } from './pages/PageManagement'

export function Admin() {
  const currentPath = useLocation().pathname.split('/').pop()

  const [tab, setTab] = useState<string>()
  useEffect(() => {
    setTab(currentPath)
  }, [currentPath])

  const navigate = useNavigate()

  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: '#db3125' },
        },
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ margin: '8px' }}>
        <Typography variant="h4">Admin Console</Typography>
        <Box sx={{ rborderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tab}
            onChange={(_: React.SyntheticEvent, newValue: string) => {
              setTab(newValue)
              navigate(newValue)
            }}
            aria-label="admin tabs"
          >
            <Tab label="Pages" value="pages" />
            <Tab label="Photos" value="photos" />
            <Tab label="Uploads" value="upload" />
          </Tabs>
        </Box>
        <Routes>
          <Route path={`/pages`} element={<PageManagement />} />
          <Route path={`/upload`} element={<UploadManager />} />
          <Route path={`/photos`} element={<PhotoManagement />} />
          <Route path={`/`} element={<Navigate to="pages" />} />
        </Routes>
      </Box>
    </ThemeProvider>
  )
}
