import { GridColDef } from '@mui/x-data-grid'
import { TableProps } from '../../../utilities/CRUDContainer'
import { Page } from 'types'
import { CRUDTable } from '../../../utilities/CRUDTable'

const columns: GridColDef[] = [
  { field: 'title', headerName: 'Title', flex: 2 },
  { field: 'publishedStatus', headerName: 'Status', flex: 1 },
  { field: 'updateTime', headerName: 'Update Time', flex: 2 },
]

export function PageManagementTable(props: TableProps<Page>) {
  return <CRUDTable columns={columns} itemName="page" {...props} />
}
