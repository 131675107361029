import { Box, CircularProgress } from '@mui/material'

export function Spinner() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '300px',
      }}
    >
      <CircularProgress size="3rem" sx={{ color: 'black' }} />
    </Box>
  )
}
