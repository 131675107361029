import { Box, TextField, Typography } from '@mui/material'
import { PageElement } from 'types'
import { TextPageElementDetail } from './TextPageElementDetail'
import { FormikErrors } from 'formik'
import { pageElementDisplayMapping } from '../utils'
import { GalleryPageElementDetail } from './GalleryPageElementDetail'
import { TripleImagePageElementDetail } from './TripleImagePageElementDetail'

export function PageElementDetail(props: {
  element?: PageElement
  editMode: boolean
  errors?: FormikErrors<PageElement>
  onUpdateField: (field: string, value: any) => void
}) {
  const DetailComponentMapping = {
    TEXT: TextPageElementDetail,
    GALLERY: GalleryPageElementDetail,
    TRIPLE_IMAGE: TripleImagePageElementDetail,
  }

  if (!props.element) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          Select an element to edit.
        </Typography>
      </Box>
    )
  }

  const DetailComponent = DetailComponentMapping[props.element.type]

  return (
    <Box>
      <Typography variant="h6">Element Detail</Typography>
      <Typography variant="subtitle1" gutterBottom>
        {pageElementDisplayMapping[props.element.type]}
      </Typography>
      <TextField
        error={!!props.errors?.name}
        required={props.editMode}
        fullWidth
        value={props.element.name}
        label="Name"
        helperText="For organization purposes only. Does not affect page rendering."
        margin="dense"
        onChange={
          props.editMode
            ? event => props.onUpdateField('name', event.target.value)
            : undefined
        }
        inputProps={{ readOnly: !props.editMode }}
      />
      <DetailComponent
        element={props.element as any}
        editMode={props.editMode}
        errors={props.errors as any}
        onUpdateField={props.onUpdateField}
      />
    </Box>
  )
}
