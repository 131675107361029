import { GridColDef } from '@mui/x-data-grid'
import { Photo } from 'types'
import {
  datetimeDisplay,
  exposureTimeDisplay,
  fNumberDisplay,
} from '../../../utilities/utils'
import { TableProps } from '../../../utilities/CRUDContainer'
import { CRUDTable } from '../../../utilities/CRUDTable'
import { currentStage } from 'config'

const columns: GridColDef[] = [
  {
    field: '_entryId', // Unique key for the row
    headerName: 'Preview',
    width: 135,
    renderCell: params => (
      <img
        src={`https://jasonandfriends-${currentStage}-pictures-bucket.s3.amazonaws.com/processed/${params.row.entryId}/thumbnail_${params.row.entryId}`}
        alt={params.row.entryId}
        style={{ height: '100%' }}
      />
    ),
  },
  { field: 'entryId', headerName: 'ID', flex: 2 },
  { field: 'title', headerName: 'Title', flex: 2 },
  { field: 'uploadStatus', headerName: 'Upload Status', flex: 1 },
  { field: 'publishedStatus', headerName: 'Published Status', flex: 1 },
  { field: 'iso', headerName: 'ISO', flex: 1 },
  {
    field: 'fNumber',
    headerName: 'F Number',
    flex: 1,
    valueGetter: fNumberDisplay,
  },
  {
    field: 'exposureTime',
    headerName: 'Exposure Time',
    flex: 1,
    valueGetter: exposureTimeDisplay,
  },
  { field: 'focalLength', headerName: 'Focal Length', flex: 1 },
  { field: 'bodyModel', headerName: 'Body Model', flex: 1 },
  { field: 'lensModel', headerName: 'Lens Model', flex: 1 },
  {
    field: 'dateTimeOriginal',
    headerName: 'Capture Time',
    flex: 1.5,
    valueGetter: datetimeDisplay,
  },

  {
    field: 'uploadTime',
    headerName: 'Upload Time',
    flex: 1,
    valueGetter: datetimeDisplay,
  },
]

export function PhotoManagementTable(props: TableProps<Photo>) {
  return <CRUDTable columns={columns} itemName="photo" {...props} />
}
