import { Box } from '@mui/material'
import { PageComponent } from './Page'
import { usePages } from './PageProvider'
import { Spinner } from './Spinner'

export function Featured() {
  const { featured, loading } = usePages()

  if (loading) {
    return <Spinner />
  }

  if (!featured) {
    return null
  }
  return (
    <Box sx={{ padding: '0 10px' }}>
      <PageComponent page={featured} showTitle={false} />
    </Box>
  )
}
