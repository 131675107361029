import { Box, Typography } from '@mui/material'
import { currentStage } from 'config'
import {
  GalleryPageElement,
  Page,
  TextPageElement,
  TripleImagePageElement,
} from 'types'
import { VerticalFlexGallery } from '../../utilities/VerticalFlexGallery'
import { useWindowSize } from '../../utilities/useWindowSize'
import { useMemo } from 'react'
import { usePhotoDetail } from './PhotoDetail'
import { useIsMobile } from '../../utilities/utils'

export function PageComponent({
  page,
  showTitle = true,
  showDate = false,
}: {
  page: Page
  showTitle?: boolean
  showDate?: boolean
}) {
  return (
    <Box>
      {showDate && (
        <Box sx={{ marginBottom: '10px' }}>
          <Typography variant="h6">
            {new Date(Number(page.updateTime)).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </Typography>
        </Box>
      )}

      {showTitle && (
        <Box>
          <Typography variant="h2">{page.title}</Typography>
        </Box>
      )}

      {page.content.map((element, index) => {
        switch (element.type) {
          case 'GALLERY':
            return <GalleryComponent key={index} gallery={element} />
          case 'TRIPLE_IMAGE':
            return <TripleImageComponent key={index} tripleImage={element} />
          case 'TEXT':
            return <TextComponent key={index} text={element} />
          default:
            return null
        }
      })}
    </Box>
  )
}

function GalleryComponent(props: { gallery: GalleryPageElement }) {
  const { viewPhoto, setAvailablePhotos } = usePhotoDetail()

  const availablePhotos = useMemo(() => {
    return props.gallery.photoIds
  }, [props.gallery.photoIds])

  const { innerWidth } = useWindowSize()
  const colummWidth = useMemo(() => {
    if (!innerWidth) {
      return 400
    }
    if (innerWidth > 2500) {
      return 800
    }

    if (innerWidth > 1500) {
      return 600
    }

    return 400
  }, [innerWidth])

  return (
    <VerticalFlexGallery
      columnWidth={colummWidth}
      components={props.gallery.photoIds.map(photoId => (
        <img
          src={`https://jasonandfriends-${currentStage}-pictures-bucket.s3.amazonaws.com/processed/${photoId}/medium_${photoId}`}
          alt={photoId}
          style={{
            width: '100%',
            height: 'auto',
            display: 'block',
            cursor: 'pointer',
          }}
          onClick={() => {
            viewPhoto(photoId)
            setAvailablePhotos(availablePhotos)
          }}
        />
      ))}
    />
  )
}

function TripleImageComponent(props: { tripleImage: TripleImagePageElement }) {
  const { viewPhoto, setAvailablePhotos } = usePhotoDetail()

  const isMobile = useIsMobile()

  return (
    <Box>
      <img
        src={`https://jasonandfriends-${currentStage}-pictures-bucket.s3.amazonaws.com/processed/${props.tripleImage.photoIds[0]}/medium_${props.tripleImage.photoIds[0]}`}
        alt={props.tripleImage.photoIds[0]}
        style={{ width: isMobile ? '100%' : '350px', cursor: 'pointer' }}
        onClick={() => {
          viewPhoto(props.tripleImage.photoIds[0])
          setAvailablePhotos(props.tripleImage.photoIds)
        }}
      />
      <img
        src={`https://jasonandfriends-${currentStage}-pictures-bucket.s3.amazonaws.com/processed/${props.tripleImage.photoIds[1]}/medium_${props.tripleImage.photoIds[1]}`}
        alt={props.tripleImage.photoIds[1]}
        style={{ width: isMobile ? '100%' : '350px', cursor: 'pointer' }}
        onClick={() => {
          viewPhoto(props.tripleImage.photoIds[1])
          setAvailablePhotos(props.tripleImage.photoIds)
        }}
      />
      <img
        src={`https://jasonandfriends-${currentStage}-pictures-bucket.s3.amazonaws.com/processed/${props.tripleImage.photoIds[2]}/medium_${props.tripleImage.photoIds[2]}`}
        alt={props.tripleImage.photoIds[2]}
        style={{ width: isMobile ? '100%' : '350px', cursor: 'pointer' }}
        onClick={() => {
          viewPhoto(props.tripleImage.photoIds[2])
          setAvailablePhotos(props.tripleImage.photoIds)
        }}
      />
      <p>{props.tripleImage.text}</p>
    </Box>
  )
}

function TextComponent(props: { text: TextPageElement }) {
  return <p>{props.text.text}</p>
}
