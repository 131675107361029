import { Photo } from 'types'
import { Box, Checkbox, Typography } from '@mui/material'
import { currentStage } from 'config'
import { useComponentSizeAndPosition } from './utils'
import { DragEndEvent } from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DndContextWithSensor, SortableElement } from './dnd'
import DeleteIcon from '@mui/icons-material/Delete'
import { fetchAllPhotos } from './api'

export function useChooseAvailablePhotos(props: {
  selectedPhotoIds: string[]
  setSelectedPhotoIds: (photoIds: string[]) => void
}) {
  const [availablePhotos, setAvailablePhotos] = useState<Photo[]>([])
  const [availablePhotosFetchError, setAvailablePhotosFetchError] =
    useState<boolean>(false)

  const [loading, setLoading] = useState(false)

  const fetchAvailablePhotos = useCallback(async () => {
    try {
      setLoading(true)
      const photos = await fetchAllPhotos()
      setAvailablePhotos(photos)
    } catch (e) {
      setAvailablePhotosFetchError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchAvailablePhotos()
  }, [fetchAvailablePhotos])

  const availablePhotosWithSelected = useMemo(() => {
    const selectedPhotosSet = new Set(props.selectedPhotoIds)

    return availablePhotos.map(photo => ({
      ...photo,
      selected: selectedPhotosSet.has(photo.entryId),
    }))
  }, [availablePhotos, props.selectedPhotoIds])

  const onTogglePhoto = useCallback(
    (photoId: string) => {
      const newPhotoIds = props.selectedPhotoIds.includes(photoId)
        ? props.selectedPhotoIds.filter(id => id !== photoId)
        : [...props.selectedPhotoIds, photoId]

      props.setSelectedPhotoIds(newPhotoIds)
    },
    [props]
  )

  return {
    availablePhotosWithSelected,
    loading,
    availablePhotosFetchError,
    onTogglePhoto,
  }
}

export function SquareCheckboxPhotoGrid(props: {
  readonly?: boolean
  photos: Array<Photo & { selected: boolean }>
  emptyText: string
  onTogglePhoto?: (photoId: string) => void
  minSize: number
}) {
  if (!props.readonly && !props.onTogglePhoto) {
    throw new Error('onTogglePhoto is required when readonly is false')
  }

  const { boxRef, width: totalWidth } = useComponentSizeAndPosition()

  // Calculate the width of each photo based on the total width of the container such that each photo is minimum
  const calculatedWidth =
    Math.floor(totalWidth / Math.floor(totalWidth / props.minSize)) - 2

  return (
    <Box sx={{ minHeight: '200px', width: '100%' }} ref={boxRef}>
      {props.photos.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Typography variant="body2">{props.emptyText}</Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          {props.photos.map(photo => (
            <SquareCheckboxPhoto
              readonly={props.readonly}
              key={photo.entryId}
              photo={photo}
              size={calculatedWidth}
              onTogglePhoto={props.onTogglePhoto || (() => {})}
            />
          ))}
        </Box>
      )}
    </Box>
  )
}

export function SquareCheckboxPhoto(props: {
  readonly?: boolean
  photo: Photo & { selected: boolean }
  size: number
  onTogglePhoto: (photoId: string) => void
}) {
  return (
    <Box
      sx={{
        width: `${props.size}px`,
        height: `${props.size}px`,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #ccc',
        position: 'relative',
      }}
      onMouseDown={() => props.onTogglePhoto(props.photo.entryId)}
    >
      <img
        src={`https://jasonandfriends-${currentStage}-pictures-bucket.s3.amazonaws.com/processed/${props.photo.entryId}/thumbnail_${props.photo.entryId}`}
        alt={props.photo.entryId}
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          cursor: 'pointer',
        }}
      />
      {!props.readonly && props.photo.selected && (
        <>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              zIndex: 1,
              pointerEvents: 'none',
            }}
          />
          <Checkbox
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 2,
              pointerEvents: 'none',
            }}
            checked
          />
        </>
      )}
    </Box>
  )
}

export function ReadonlySelectedPhotos(props: { photoIds: string[] }) {
  return (
    <SquareCheckboxPhotoGrid
      photos={props.photoIds.map(
        entryId => ({ entryId } as Photo & { selected: boolean })
      )}
      readonly
      emptyText="No photos selected."
      minSize={150}
    />
  )
}

export function SelectedPhotos(props: {
  selectedPhotos: string[]
  updateSelectedPhotoIds: (photoIds: string[]) => void
  onTogglePhoto: (photoId: string) => void
}) {
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      // Handle reordering and select case
      if (event.active && event.over) {
        const oldIndex = props.selectedPhotos.findIndex(
          photoId => photoId === event.active.id
        )
        const newIndex = props.selectedPhotos.findIndex(
          photoId => photoId === event.over!.id
        )

        if (oldIndex !== -1 && newIndex !== -1) {
          props.updateSelectedPhotoIds(
            arrayMove(props.selectedPhotos, oldIndex, newIndex)
          )
        }
      }
    },
    [props]
  )

  return (
    <DndContextWithSensor onDragEnd={handleDragEnd}>
      <SortableContext
        items={props.selectedPhotos}
        strategy={verticalListSortingStrategy}
      >
        <Box sx={{ minHeight: '200px', width: '200px', flexShrink: 0 }}>
          <Typography variant="subtitle2">Selected Photos</Typography>
          <Typography variant="body2">Drag to reorder photos</Typography>
          {props.selectedPhotos.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Typography variant="body2">No photos selected.</Typography>
            </Box>
          ) : (
            props.selectedPhotos.map(photoId => (
              <SortableElement key={photoId} id={photoId} showTransition>
                <SelectedPhoto
                  key={photoId}
                  photoId={photoId}
                  onTogglePhoto={() => props.onTogglePhoto(photoId)}
                />
              </SortableElement>
            ))
          )}
        </Box>
      </SortableContext>
    </DndContextWithSensor>
  )
}

export function SelectedPhoto(props: {
  photoId: string
  onTogglePhoto: () => void
}) {
  return (
    <Box sx={{ position: 'relative' }}>
      <img
        src={`https://jasonandfriends-${currentStage}-pictures-bucket.s3.amazonaws.com/processed/${props.photoId}/thumbnail_${props.photoId}`}
        alt={props.photoId}
        style={{
          width: '100%',
          cursor: 'pointer',
        }}
      />
      <DeleteIcon
        onClick={props.onTogglePhoto}
        color="error"
        fontSize="medium"
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          zIndex: 2,
          cursor: 'pointer',
          boxShadow: '0px 0 8px 2px rgba(0, 0, 0, 0.3)', // Adds the box shadow
          borderRadius: '5px', // Ensures the box shadow stays square
          backgroundColor: 'rgba(255, 255, 255, 0.9)', // Ensures the box shadow is visible
        }}
      />
    </Box>
  )
}

export function AvailablePhotos(props: {
  availablePhotos: Array<Photo & { selected: boolean }>
  onTogglePhoto: (photoId: string) => void
}) {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="subtitle2">Available Photos</Typography>
      <Typography variant="body2">Click to select photos</Typography>
      <SquareCheckboxPhotoGrid
        photos={props.availablePhotos}
        onTogglePhoto={props.onTogglePhoto}
        readonly={false}
        emptyText='No photos available. Upload photos in the "Upload" tab.'
        minSize={150}
      />
    </Box>
  )
}
