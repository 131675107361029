import { TextField } from '@mui/material'
import { FormikErrors } from 'formik'
import { TextPageElement } from 'types'

export function TextPageElementDetail(props: {
  element?: TextPageElement
  editMode: boolean
  errors?: FormikErrors<TextPageElement>
  onUpdateField: (field: string, value: any) => void
}) {
  return (
    <TextField
      required={props.editMode}
      multiline
      fullWidth
      error={!!props.errors?.text}
      value={props.element?.text}
      label="Contents"
      margin="dense"
      onChange={event => props.onUpdateField('text', event.target.value)}
      helperText="The text to display on the page. Multi-line supported."
      inputProps={{ readOnly: !props.editMode }}
    />
  )
}
