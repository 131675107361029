import { FormikErrors } from 'formik'
import { useCallback } from 'react'
import { GalleryPageElement, PageElement } from 'types'
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material'
import {
  AvailablePhotos,
  ReadonlySelectedPhotos,
  SelectedPhotos,
  useChooseAvailablePhotos,
} from '../../../../../utilities/GalleryComponents'

export function GalleryPageElementDetail(props: {
  element?: GalleryPageElement
  editMode: boolean
  errors?: FormikErrors<PageElement>
  onUpdateField: (field: string, value: any) => void
}) {
  const updateSelectedPhotoIds = useCallback(
    (photoIds: string[]) => {
      props.onUpdateField('photoIds', photoIds)
    },
    [props]
  )

  const {
    availablePhotosWithSelected,
    loading,
    availablePhotosFetchError,
    onTogglePhoto,
  } = useChooseAvailablePhotos({
    selectedPhotoIds: props.element!.photoIds,
    setSelectedPhotoIds: updateSelectedPhotoIds,
  })

  return (
    <Box>
      <Typography variant="subtitle1">Photos</Typography>
      {loading && props.editMode ? (
        <CircularProgress />
      ) : availablePhotosFetchError && props.editMode ? (
        <Alert severity="error">
          Failed to load available photos. Please try again later.
        </Alert>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {props.editMode ? (
            <SelectedPhotos
              selectedPhotos={props.element!.photoIds}
              updateSelectedPhotoIds={updateSelectedPhotoIds}
              onTogglePhoto={onTogglePhoto}
            />
          ) : (
            <ReadonlySelectedPhotos photoIds={props.element!.photoIds} />
          )}
          {props.editMode && (
            <>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ margin: '0 8px' }}
              />
              <AvailablePhotos
                availablePhotos={availablePhotosWithSelected}
                onTogglePhoto={onTogglePhoto}
              />
            </>
          )}
        </Box>
      )}
    </Box>
  )
}
