import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Page } from 'types'
import { fetchAllPages } from '../../utilities/api'
import { useBanner } from '../BannerProvider'

const PagesContext = createContext<{
  loading: boolean
  featured?: Page
  galleries: Page[]
}>({
  loading: true,
  featured: undefined,
  galleries: [],
})

const usePages = () => useContext(PagesContext)

function PagesProvider({ children }: { children: React.ReactNode }) {
  const { showBanner } = useBanner()

  const [loading, setLoading] = useState(true)
  const [featured, setFeatured] = useState<Page>()
  const [galleries, setGalleries] = useState<Page[]>([])

  const fetchAndSetPages = useCallback(async () => {
    try {
      setLoading(true)
      const result = await fetchAllPages()

      const featured = result.find(
        page => page.title.toLowerCase() === 'featured'
      )
      if (!featured) {
        throw new Error('Featured page not found')
      }

      const galleries = result.filter(
        page =>
          page.title.toLowerCase() !== 'featured' &&
          page.publishedStatus === 'PUBLISHED'
      )

      setFeatured(featured)
      setGalleries(galleries)
    } catch (e) {
      console.error(e)
      showBanner(`PagesContext error: ${e}`, 'error')
    } finally {
      setLoading(false)
    }
  }, [showBanner])

  useEffect(() => {
    fetchAndSetPages()
  }, [fetchAndSetPages])

  return (
    <PagesContext.Provider
      value={{
        loading,
        featured,
        galleries,
      }}
    >
      {children}
    </PagesContext.Provider>
  )
}

export { PagesProvider, usePages }
