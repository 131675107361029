import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { TableProps } from './CRUDContainer'
import {
  Box,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'
import { useMemo, useState } from 'react'
import { BaseModel } from 'types'

export type DisplayColumn = GridColDef

export interface CRUDTableProps {
  columns: DisplayColumn[]
  itemName: string
}

export function CRUDTable<T extends BaseModel>(
  props: TableProps<T> & CRUDTableProps
) {
  const rows = useMemo(
    () =>
      props.data.map(page => ({
        ...page,
        id: page.entryId,
      })),
    [props.data]
  )
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  if (props.loading) {
    return (
      <Box sx={{ padding: '8px' }}>
        <CircularProgress size={30} />
      </Box>
    )
  }

  return (
    <section style={{ height: '100%' }}>
      <Box sx={{ marginBottom: '8px' }}>
        <Button onClick={props.onRefresh}>Refresh</Button>
        {props.onCreate && <Button onClick={props.onCreate}>Create</Button>}

        {selectedRows.length > 0 && (
          <Button
            onClick={() => setShowDeleteModal(true)}
            sx={{ marginLeft: '4px' }}
          >
            Delete
          </Button>
        )}
      </Box>

      <DataGrid
        autoHeight
        rowHeight={75}
        onRowClick={row => props.onRowClick(row.row as T)}
        rows={rows}
        columns={props.columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100]}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={newSelection =>
          setSelectedRows(newSelection as string[])
        }
        rowSelectionModel={selectedRows}
        sx={{
          // pointer cursor on ALL rows
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
        }}
      />

      <Dialog
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        aria-labelledby={`delete-${props.itemName}s-dialog-title`}
        aria-describedby={`delete-${props.itemName}s-dialog-description`}
      >
        <DialogTitle
          id={`delete-${props.itemName}s-dialog-title`}
        >{`Delete ${props.itemName}s?`}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id={`delete-${props.itemName}s-dialog-description`}
          >
            {`Are you sure you want to delete the selected ${props.itemName}s? This action is
            permanent and cannot be undone.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button
            onClick={() => {
              props.onDeleteRows(
                selectedRows.map(
                  row =>
                    ({
                      entryId: row,
                    } as T)
                )
              )
              setShowDeleteModal(false)
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  )
}
