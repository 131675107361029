import { Navigate, Route, Routes } from 'react-router-dom'
import { Box } from '@mui/material'
import { Featured } from './Featured'
import { Galleries } from './Galleries'
import { Header } from './Header'
import { Splash } from './Splash'
import { Footer } from './Footer'

export function ContentRouter() {
  const isMobile = window.innerWidth < 768

  return (
    <Routes>
      <Route
        path={`/featured/*`}
        element={
          <Box>
            <Header>
              <Featured />
            </Header>
            <Footer />
          </Box>
        }
      />
      <Route
        path={`/gallery/*`}
        element={
          <Box>
            <Header>
              <Galleries />
            </Header>
            <Footer />
          </Box>
        }
      />

      <Route
        path={`/`}
        element={
          // Skip the splash screen on mobile
          isMobile ? (
            <Navigate to="/featured" />
          ) : (
            <Box>
              <Splash />
              <Footer />
            </Box>
          )
        }
      />
    </Routes>
  )
}
