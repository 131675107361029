import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material'
import { Form, Formik, FormikConfig, FormikValues } from 'formik'
import { ReactElement, useCallback, useState } from 'react'
import { DetailProps } from './CRUDContainer'

export interface CardDetailFormProps<T extends FormikValues> {
  itemName: string
  initialValues: Partial<T>
  mandatoryFields?: Array<keyof T>
  formValidate?: FormikConfig<T>['validate']

  FormFieldsComponent: (props: {
    editMode: boolean
    values: T
    setFieldValue: (field: string, value: any) => void
    setFieldTouched: (field: string, touched?: boolean) => void
  }) => ReactElement
}

export function CardDetailFormWrapper<T extends FormikValues>(
  props: DetailProps<T> & CardDetailFormProps<T>
) {
  const [editMode, setEditMode] = useState<boolean>(props.isCreate)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const validate = useCallback(
    async (values: T) => {
      const errors: { [key in keyof T]: string } = {} as {
        [key in keyof T]: string
      }
      if (props.mandatoryFields) {
        props.mandatoryFields.forEach(field => {
          if (!values[field]) {
            errors[field] = 'This field is required'
          }
        })
      }
      if (props.formValidate) {
        const additionalErrors = await props.formValidate(values)
        Object.assign(errors, additionalErrors)
      }

      return errors
    },
    [props]
  )

  const FormFieldsComponent = props.FormFieldsComponent

  if (props.loading) {
    return (
      <Card>
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Card>
    )
  }

  return (
    <Formik
      validate={validate}
      enableReinitialize
      initialValues={props.item ?? (props.initialValues as T)}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        const result = await props.onSubmit(values)
        setSubmitting(false)

        if (result) {
          setEditMode(false)
        }
      }}
    >
      {({
        isSubmitting,
        setValues,
        values,
        setFieldValue,
        touched,
        errors,
        setTouched,
        setFieldTouched,
        submitCount,
      }) => (
        <Card>
          <CardActions>
            <Button onClick={props.onClose}>
              {props.isCreate ? 'Cancel' : 'Close'}
            </Button>

            {!props.isCreate && (
              <Button
                onClick={() => {
                  if (editMode && props.item) {
                    setValues(props.item)
                    setTouched({})
                  }
                  setEditMode(!editMode)
                }}
              >
                {editMode ? 'Reset' : 'Edit'}
              </Button>
            )}

            {!props.isCreate && (
              <Button onClick={() => setShowDeleteModal(true)}>Delete</Button>
            )}
          </CardActions>
          <Divider />

          <CardContent>
            {props.detailBanner && (
              <Alert
                severity={props.detailBanner.isSuccess ? 'success' : 'error'}
                sx={{ marginBottom: '10px' }}
              >
                {props.detailBanner.content}
              </Alert>
            )}
            <Typography gutterBottom variant="h5" component="div">
              {props.item?.title}
            </Typography>

            <Box>
              <Form noValidate>
                <FormFieldsComponent
                  editMode={editMode}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />

                {editMode && (
                  <Box style={{ display: 'flex', marginTop: '10px' }}>
                    <Button
                      disabled={
                        isSubmitting ||
                        props.loading ||
                        // Errors are calculated every input change, but we only want to disable the button if there are errors and the user has submitted the form.
                        (submitCount > 0 && Object.keys(errors).length > 0) ||
                        // If the user hasn't touched any fields in edit mode, we don't want to disable the button.
                        (editMode &&
                          !props.isCreate &&
                          Object.keys(touched).length === 0)
                      }
                      type="submit"
                    >
                      {props.isCreate ? 'Create' : 'Update'}
                    </Button>
                    {(isSubmitting || props.loading) && <CircularProgress />}
                  </Box>
                )}
              </Form>
            </Box>
          </CardContent>

          <Dialog
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            aria-labelledby={`delete-${props.itemName}-dialog-title`}
            aria-describedby={`delete-${props.itemName}-dialog-description`}
          >
            <DialogTitle id={`delete-${props.itemName}-dialog-title`}>
              Delete {props.itemName}?
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id={`delete-${props.itemName}-dialog-description`}
              >
                Are you sure you want to delete this {props.itemName}? This
                action is permanent and cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
              <Button onClick={() => props.onDelete(props.item as T)} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      )}
    </Formik>
  )
}
