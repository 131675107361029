import React from 'react'
import { Modal, Backdrop, Fade, Box } from '@mui/material'
import { styled } from '@mui/system'

// Styled Modal without padding/margin
const StyledModal = styled(Modal)({
  '& .MuiBox-root': {
    margin: 0,
    padding: 0,
  },
  '& .MuiModal-container': {
    margin: 0,
    padding: 0,
  },
})

export function FadeInModal(props: {
  children: React.ReactElement
  onClose: () => void
  onKeyDown?: (e: React.KeyboardEvent) => void
  open: boolean
}) {
  return (
    <StyledModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      onClose={props.onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      onKeyDown={props.onKeyDown}
      open={props.open}
    >
      <Fade in={true}>
        <Box>{props.children}</Box>
      </Fade>
    </StyledModal>
  )
}
