import { Box, Typography } from '@mui/material'
import { usePages } from './PageProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import { PageComponent } from './Page'
import { useEffect } from 'react'
import { Spinner } from './Spinner'
import { useIsMobile } from '../../utilities/utils'

export function Galleries() {
  const currentPath = useLocation().pathname
  const navigate = useNavigate()

  const { galleries, loading } = usePages()

  const selectedGallery = galleries.find(gallery =>
    currentPath.includes(gallery.navigationValue)
  )

  useEffect(() => {
    if (!selectedGallery && galleries.length > 0) {
      navigate(`/gallery/${galleries[0].navigationValue}`)
    }
  }, [galleries, navigate, selectedGallery])

  const isMobile = useIsMobile()

  if (loading) {
    return <Spinner />
  }

  // Map through the galleries and create a side bar with links to each gallery
  return (
    <Box sx={{ display: 'flex', paddingTop: isMobile ? '20px' : '50px' }}>
      {!isMobile && (
        <Box
          sx={{
            marginRight: '30px',
            textAlign: 'right',
            width: '200px',
            flexShrink: 0,
          }}
        >
          {galleries.map(gallery => (
            <Typography
              key={gallery.entryId}
              variant="subtitle1"
              sx={{
                textDecoration: currentPath.includes(gallery.navigationValue)
                  ? 'underline'
                  : 'none',
                textUnderlineOffset: '5px',
                cursor: 'pointer',
              }}
              onClick={() => navigate(`/gallery/${gallery.navigationValue}`)}
            >
              {gallery.navigationLabel}
            </Typography>
          ))}
        </Box>
      )}

      {selectedGallery && (
        <Box sx={{ padding: '0 10px' }}>
          <PageComponent page={selectedGallery} showTitle showDate />
        </Box>
      )}
    </Box>
  )
}
