import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useBanner } from '../components/BannerProvider'

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export interface CRUDProps<T> {
  retrievalFunction?: (id: string) => Promise<T | undefined>
  listFunction?: () => Promise<T[]>
  createFunction?: (item: T) => Promise<T>
  updateFunction?: (item: T) => Promise<T>
  deleteFunction?: (item: T) => Promise<void>
  bulkDeleteFunction?: (items: T[]) => Promise<void>
  itemIdKey: keyof T
  itemDisplayName: string
  skipSetCreateToFalseAfterCreate?: boolean
  shouldRenderHeader?: boolean
  deepLinkSearchParam?: string
}

export interface CRUDFeatures {
  canCreate: boolean
  canUpdate: boolean
  canDelete: boolean
  canBulkDelete: boolean
  canList: boolean
  canRetrieve: boolean
}

/**
 * Generates basic CRUD utilities with deep linking support.
 */
export function useCRUDWithDeepLinkingUtils<T>({
  createFunction,
  deepLinkSearchParam = 'detail_id',
  deleteFunction,
  bulkDeleteFunction,
  itemDisplayName,
  itemIdKey,
  listFunction,
  retrievalFunction,
  skipSetCreateToFalseAfterCreate,
  updateFunction,
}: CRUDProps<T>) {
  // At least one of list or retrieve must be defined
  if (!listFunction && !retrievalFunction) {
    throw new Error('At least one of list or retrieve must be defined')
  }

  const navigate = useNavigate()
  const location = useLocation()

  const [detailLoading, setDetailLoading] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<T>()

  const [listLoading, setListLoading] = useState<boolean>(false)
  const [items, setItems] = useState<T[]>([])

  // When this hook is not passed a retrieve function, the retrieveItem function which is returned to callers will attempt to find the item in the list. However, for initial renders where the detail id is passed in but the list is not yet loaded, we need to store the promise for the list so that we can wait for it in the retrieveItem function to load before attempting to find the item in the list.
  const [itemsResponsePromise, setItemsResponsePromise] =
    useState<Promise<T[]>>()

  const { showBanner } = useBanner()
  const [detailBanner, setDetailBanner] = useState<{
    content: string
    isSuccess: boolean
  }>()

  const [isCreate, setCreate] = useState<boolean>(false)

  const pushToHistory = useCallback(
    (id: string) => {
      const url = new URL(window.location.href)
      url.searchParams.set(deepLinkSearchParam, id)

      navigate(url.pathname + url.search)
    },
    [deepLinkSearchParam, navigate]
  )

  const removeFromHistory = useCallback(() => {
    const url = new URL(window.location.href)
    url.searchParams.delete(deepLinkSearchParam)

    navigate(url.pathname + url.search)
    setSelectedItem(undefined)
  }, [deepLinkSearchParam, navigate])

  const retrieveItem = useCallback(
    async (id: string): Promise<T | undefined> => {
      if (!retrievalFunction) {
        console.warn('No retrieve function provided.')
        return undefined
      }

      try {
        setDetailLoading(true)
        return await retrievalFunction(id)
      } catch (e) {
        showBanner(
          `Error retrieving ${capitalizeFirstLetter(
            itemDisplayName
          )} with id ${id}: ${(e as any).message}`,
          'error'
        )
        return undefined
      } finally {
        setDetailLoading(false)
      }
    },
    [itemDisplayName, retrievalFunction, showBanner]
  )

  const loadItems = useCallback(async () => {
    if (!listFunction) {
      console.warn('No list function provided.')
      return undefined
    }

    try {
      setListLoading(true)
      const listPromise = listFunction()
      setItemsResponsePromise(listPromise)

      setItems(await listPromise)

      return true
    } catch (e) {
      showBanner(
        `Error listing ${itemDisplayName}s: ${(e as any).message}`,
        'error'
      )
      return false
    } finally {
      setListLoading(false)
    }
  }, [itemDisplayName, listFunction, showBanner])

  const createItem = useCallback(
    async (item: T) => {
      if (!createFunction) {
        console.warn('No create function provided.')
        return undefined
      }

      try {
        const result = await createFunction(item)
        setSelectedItem(result)
        if (listFunction) {
          setItems(await listFunction())
        }
        // Certain flows that use this hook want to keep the create flag set to true, for example impersonated token flow which only shows the token after initial create
        if (!skipSetCreateToFalseAfterCreate) {
          setCreate(false)
        }

        pushToHistory(String(result[itemIdKey]))
        setDetailBanner({
          content: `Successfully created ${itemDisplayName}.`,
          isSuccess: true,
        })
        return result
      } catch (e) {
        setDetailBanner({
          content: `Error creating ${itemDisplayName}: ${(e as any).message}`,
          isSuccess: false,
        })
        return undefined
      }
    },
    [
      createFunction,
      itemDisplayName,
      itemIdKey,
      listFunction,
      pushToHistory,
      skipSetCreateToFalseAfterCreate,
    ]
  )

  const updateItem = useCallback(
    async (item: T) => {
      if (!updateFunction) {
        console.warn('No update function provided.')
        return undefined
      }

      try {
        setDetailLoading(true)

        const result = await updateFunction(item)
        setSelectedItem(result)
        if (listFunction) {
          setItems(await listFunction())
        }
        setDetailBanner({
          content: `Successfully updated ${itemDisplayName}.`,
          isSuccess: true,
        })
        return result
      } catch (e) {
        setDetailBanner({
          content: `Error updating ${itemDisplayName}: ${(e as any).message}`,
          isSuccess: false,
        })
        return undefined
      } finally {
        setDetailLoading(false)
      }
    },
    [itemDisplayName, listFunction, updateFunction]
  )

  const deleteItem = useCallback(
    async (item: T) => {
      if (!deleteFunction) {
        console.warn('No delete function provided.')
        return false
      }

      try {
        setDetailLoading(true)
        await deleteFunction(item)
        if (listFunction) {
          setItems(await listFunction())
        }
        removeFromHistory()
        showBanner(`Successfully deleted ${itemDisplayName}.`, 'success', 3000)

        return true
      } catch (e) {
        setDetailBanner({
          content: `Error deleting ${itemDisplayName}: ${(e as any).message}`,
          isSuccess: false,
        })
        return false
      } finally {
        setDetailLoading(false)
      }
    },
    [
      deleteFunction,
      itemDisplayName,
      listFunction,
      removeFromHistory,
      showBanner,
    ]
  )

  const bulkDeleteItems = useCallback(
    async (items: T[]) => {
      if (!bulkDeleteFunction) {
        console.warn('No bulk delete function provided.')
        return false
      }

      try {
        setListLoading(true)
        await bulkDeleteFunction(items)
        if (listFunction) {
          setItems(await listFunction())
        }
        showBanner(`Successfully deleted ${itemDisplayName}s.`, 'success', 3000)

        return true
      } catch (e) {
        showBanner(
          `Error deleting ${itemDisplayName}s: ${(e as any).message}`,
          'error'
        )
        return false
      } finally {
        setListLoading(false)
      }
    },
    [bulkDeleteFunction, itemDisplayName, listFunction, showBanner]
  )

  useEffect(() => {
    ;(async () => {
      const detailId = new URLSearchParams(window.location.search).get(
        deepLinkSearchParam
      )
      if (!detailId && selectedItem) {
        removeFromHistory()
        return
      }

      // If there's already a selected item, don't do this (i.e. immediately after create)
      // *** In order for this to work properly, all detail components must set selectedItem to undefined when they unmount. ***

      if (!detailId || selectedItem || detailLoading) {
        return
      }

      setDetailLoading(true)
      // If there is no retrieve item function, try to load the item from the list. Already checked that either retrieve or list exist.
      const found = await (async () => {
        if (retrievalFunction) {
          return await retrieveItem(detailId)
        }
        // The useEffect should have already kicked off the initial list load, so we can just wait for that to finish, and reuse the list. This also handles the case where the list is already loaded (i.e. not the case where user enters the detail page directly via deeplinking).
        if (itemsResponsePromise) {
          try {
            return (await itemsResponsePromise).find(
              item => String(item[itemIdKey]) === detailId
            )
          } catch (e) {
            return undefined
          }
        }

        // If not, kick off the list load and wait for it to finish and find
        return (await (listFunction as () => Promise<T[]>)()).find(
          item => String(item[itemIdKey]) === detailId
        )
      })()

      if (!found) {
        removeFromHistory()
      } else {
        setSelectedItem(found)
      }

      setDetailLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location.search,
    deepLinkSearchParam,
    // detailLoading, // This is causing a double API call
    itemIdKey,
    items,
    itemsResponsePromise,
    listFunction,
    removeFromHistory,
    retrievalFunction,
    retrieveItem,
    selectedItem,
  ])

  const features: CRUDFeatures = {
    canCreate: !!createFunction,
    canDelete: !!deleteFunction,
    canBulkDelete: !!bulkDeleteFunction,
    canList: !!listFunction,
    canRetrieve: !!retrievalFunction,
    canUpdate: !!updateFunction,
  }

  return {
    createItem,
    deleteItem,
    bulkDeleteItems,
    detailBanner,
    detailLoading,
    features,
    isCreate,
    items,
    listLoading,
    loadItems,
    pushToHistory,
    removeFromHistory,
    retrieveItem,
    selectedItem,
    setCreate,
    setDetailBanner,
    setItems,
    setSelectedItem,
    updateItem,
  }
}
